import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import firebase from 'firebase/compat/app'

const firebaseConfig = {
  apiKey: 'AIzaSyAsiSo0VkpQ6StBMpxEcQKV4Sk6KoJQnrM',
  authDomain: 'how2beast-3361c.firebaseapp.com',
  databaseURL: 'https://how2beast-3361c.firebaseio.com',
  projectId: 'how2beast-3361c',
  storageBucket: 'how2beast-3361c.appspot.com',
  messagingSenderId: '467298705751',
  appId: '1:467298705751:web:1f22550fb425cbe8155e12',
  measurementId: 'G-JNPQTPJYLR'
}
// Initialize Firebase

export default () => {
  if (firebase.apps.find(app => app.name === '[DEFAULT]')) {
    return firebase.app()
  }
  const app = firebase.initializeApp(firebaseConfig)
  app.auth()
  app
    .firestore()
    .settings({ cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED })
  try {
    app
      .firestore()
      .enablePersistence()
      .catch(() => {})
  } catch (e) {}
  return app
}

export const facebookProvider = firebase.auth.FacebookAuthProvider

export const { StringFormat } = firebase.storage
export const { FieldValue } = firebase.firestore
